import React, { useEffect, useRef, useState } from 'react'
import { Typography } from '@material-tailwind/react';
import { motion, useInView, useAnimation } from 'framer-motion'

import banner from '../../img/shop/banner.png';
import reedbanner from '../../img/shop/reedbanner.png';

import home from '../../img/shop/diffuser/home.png';
import business from '../../img/shop/diffuser/business.png';

import oriental from '../../img/shop/parfums/oriental.png';
import fresh from '../../img/shop/parfums/fresh.png';
import floral from '../../img/shop/parfums/floral.png';

import { Link, NavLink } from 'react-router-dom';
import { ShowAdvertisement } from '../../widgets/modals/advertisements/ShowAdvertisement';
import { API_LINK } from '../../apiConfig';
import axios from 'axios';
import { ShowCalendarVisitor } from '../../widgets/modals/calendars/ShowCalendarVisitor';


const Shop = () => {

  const [openShowCalendar, setOpenShowCalendar] = useState(false);
  const handleOpenShowCalendar = () => setOpenShowCalendar((cur) => !cur);

  const [calendar, setCalendar] = useState();


  const bannerControls = useAnimation()
  const refBanner = useRef(null);
  const isInViewBanner = useInView(refBanner);

  const diffuserControls = useAnimation()
  const refDiffuser = useRef(null);
  const isInViewDiffuser = useInView(refDiffuser);

  const parfumControls = useAnimation()
  const refParfum = useRef(null);
  const isInViewParfum = useInView(refParfum);


  useEffect(() => {
    if (isInViewBanner) {
      bannerControls.start("visible")
    }

    if (isInViewDiffuser) {
      diffuserControls.start("visible")
    }

    if (isInViewParfum) {
      parfumControls.start("visible")
    }
  }, [isInViewBanner, isInViewDiffuser, isInViewParfum])



  const [isActive, setIsActive] = useState(false);
  const [openShowAdvertisement, setOpenShowAdvertisement] = useState(false);
  const handleOpenShowAdvertisement = () => setOpenShowAdvertisement((cur) => !cur);
  const [advertisement, setAdvertisement] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
    });
  });


  const scrollToContent = () => {
    window.scrollBy({
      top: window.innerHeight * 1, // Faites défiler la fenêtre de visualisation vers le bas de la moitié de sa hauteur
      behavior: "smooth" // Ajoutez un comportement de défilement fluide
    });
  };

  useEffect(() => {
    axios.get(`${API_LINK}/advertisements`).then(({ data }) => {
      setAdvertisement(data);
      if (data) {
        setOpenShowAdvertisement(true)
      }
    }).catch((error) => {
      console.log(error);
    })
  }, [])


  useEffect(() => {
    axios.get(`${API_LINK}/calendars/today`).then(({ data }) => {
      setCalendar(data);
      if (data) {
        setOpenShowCalendar(true)
      }
    }).catch((error) => {
      console.log(error);
    })
  }, [])

  return (
    <div>

      <div
        className={`${isActive ? 'h-[72px]' : 'h-[88px]'} top-0 bg-white fixed w-full transition-all`}
      ></div>

      <div className='sm:mt-[110px] mt-[96px] text-info'>

        <div className='bg-secondary w-full flex justify-center rounded-none mt-5 p-5'>
          <div className='flex sm:flex-row flex-col items-center text-primary'>
            <NavLink to="/diffuser" className='text-center rounded-none w-40 hover:bg-white'>
              {({ isActive }) => (
                <Typography
                  className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                  variant="paragraph"
                >
                  Diffuseurs
                </Typography>
              )}
            </NavLink>
            <NavLink to="/parfum" className='text-center rounded-none w-40 hover:bg-white'>
              {({ isActive }) => (
                <Typography
                  className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                  variant="paragraph"
                >
                  Parfums
                </Typography>
              )}
            </NavLink>

            <NavLink to="/candle-reed" className='text-center rounded-none w-40 hover:bg-white'>
              {({ isActive }) => (
                <Typography
                  className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                  variant="paragraph"
                >
                  Candles & Reed
                </Typography>
              )}
            </NavLink>

            <NavLink to="/promotion" className='text-center rounded-none w-40 hover:bg-white'>
              {({ isActive }) => (
                <Typography
                  className={`${isActive && "border-b-2"} border-primary p-2 font-normal`}
                  variant="paragraph"
                >
                  Promotions
                </Typography>
              )}
            </NavLink>
          </div>
        </div>


        <div ref={refBanner}>
          <motion.section
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 }
            }}
            initial='hidden'
            animate={bannerControls}
            transition={{ duration: 0.5, delay: 0.25 }}
            className='relative w-full flex items-center h-full'
          >
            <img src={banner} className='sm:w-2/3 w-full h-full' loading='lazy' />
            <div className='sm:w-1/3 h-full'></div>
            <div className='sm:w-2/5 w-full absolute sm:right-0 sm:inset-y-0 flex justify-center sm:items-center items-end h-full sm:mb-0 -mb-14'>
              <div className='hidden bg-secondary sm:bg-opacity-100 bg-opacity-50 bg- h-4/5 sm:w-full w-2/3 sm:flex items-center relative sm:p-0 p-10'>
                <Typography className='sm:text-5xl text-xl font-medium font-secondary text-center mx-5 text-primary tracking-wider sm:mb-0 mb-5'>
                  Découvrez nos produits exclusifs
                </Typography>
                <button onClick={scrollToContent}
                  className="text-secondary py-4 px-8 bg-primary font-medium mt-5 hover:opacity-80 absolute -bottom-5 left-1/2 -translate-x-1/2"
                >
                  DECOUVRIR
                </button>
              </div>
              <div className="sm:hidden">
                <button onClick={scrollToContent}
                  className="text-secondary py-4 px-8 bg-primary font-medium mt-5 hover:opacity-80"
                >
                  DECOUVRIR
                </button>
              </div>
            </div>

          </motion.section>
        </div>

        <div ref={refDiffuser}>
          <motion.section
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 }
            }}
            initial='hidden'
            animate={diffuserControls}
            transition={{ duration: 0.5, delay: 0.25 }}
            className="pt-20"
          >
            <div className="container mx-auto">
              <Typography variant='h2' className="mb-4 text-center font-secondary tracking-wider font-light">UN CHOIX POUR TOUS LES BESOINS</Typography>
              <Typography variant='lead' className="mb-10 text-center">Pour tous vos espaces, selon votre identité, professionnelle et personnelle, rajoutez une
                touche de décoration moderne et parfum unique</Typography>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 lg:mx-8 gap-[30px] max-w-sm mx-auto md:max-w-none md:mx-0">


                <Link to={{ pathname: '/diffuser', search: '?type=business' }} className='relative hover:scale-105 transition duration-500 sm:mb-0 mb-6'>
                  <img src={business} loading='lazy' />
                  <div className='absolute inset-0'>
                    <div className='mt-5 flex justify-center items-center'>
                      <div className="w-20 h-[2px] mr-3 bg-primary"></div>
                      <Typography variant='h4' className="text-center text-primary">BUSINESS</Typography>
                      <div className="w-20 h-[2px] ml-3 bg-none"></div>
                    </div>
                  </div>
                  <button className="text-secondary py-4 px-8 bg-primary font-medium mt-5 hover:opacity-80 absolute -bottom-7 left-1/2 -translate-x-1/2">
                    ACHETER
                  </button>
                </Link>

                <Link to={{ pathname: '/diffuser', search: '?type=home' }} className='relative hover:scale-105 transition duration-500'>
                  <img src={home} loading='lazy' />
                  <div className='absolute inset-0'>
                    <div className='mt-5 flex justify-center items-center'>
                      <div className="w-20 h-[2px] mr-3 bg-none"></div>
                      <Typography variant='h4' className="text-center text-primary">HOME</Typography>
                      <div className="w-20 h-[2px] ml-3 bg-primary"></div>
                    </div>
                  </div>

                  <button className="text-secondary py-4 px-8 bg-primary font-medium mt-5 hover:opacity-80 absolute -bottom-7 left-1/2 -translate-x-1/2">
                    ACHETER
                  </button>
                </Link>
              </div>
            </div>
          </motion.section>
        </div>

        <div ref={refParfum}>
          <motion.section
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 }
            }}
            initial='hidden'
            animate={parfumControls}
            transition={{ duration: 0.5, delay: 0.25 }}
            className="py-20"
          >
            <div className="container mx-auto">
              <Typography variant='h2' className="mb-4 text-center font-secondary tracking-wider font-light">TOUS VOS ENVIES EN PARFUMS</Typography>
              <Typography variant='lead' className="mb-10 text-center">Votre parfum représente vos émotions, choisissez le parfum qui vous convient</Typography>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:mx-8 gap-[30px] max-w-sm mx-auto md:max-w-none md:mx-0">
                <Link to={{ pathname: '/parfum', search: '?type=oriental' }} className='hover:scale-105 transition duration-500 group'>
                  <div className='relative'>
                    <img src={oriental} loading='lazy' />
                    <div className='absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-500'>
                      <button className="text-secondary py-4 px-8 bg-primary font-medium">
                        ACHETER
                      </button>
                    </div>
                  </div>
                  <Typography variant='h4' className="mt-5 text-center">ORIENTAL</Typography>
                </Link>
                <Link to={{ pathname: '/parfum', search: '?type=fresh' }} className='hover:scale-105 transition duration-500 group'>
                  <div className='relative'>
                    <img src={fresh} loading='lazy' />
                    <div className='absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-500'>
                      <button className="text-secondary py-4 px-8 bg-primary font-medium">
                        ACHETER
                      </button>
                    </div>
                  </div>
                  <Typography variant='h4' className="mt-5 text-center">FRESH</Typography>
                </Link>
                <Link to={{ pathname: '/parfum', search: '?type=floral' }} className='hover:scale-105 transition duration-500 group'>
                  <div className='relative'>
                    <img src={floral} loading='lazy' />
                    <div className='absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-500'>
                      <button className="text-secondary py-4 px-8 bg-primary font-medium">
                        ACHETER
                      </button>
                    </div>
                  </div>
                  <Typography variant='h4' className="mt-5 text-center">FLORAL</Typography>
                </Link>
              </div>
            </div>
          </motion.section>
        </div>


        <section className='container mx-auto sm:p-10 p-5 '>
          <div className='relative hover:scale-105 transition duration-500'>
            <img src={reedbanner} className="w-full h-auto" loading='lazy' />
            <div className='absolute inset-0 w-1/2 flex justify-center items-center'>
              <div className='flex flex-col items-center '>
                <Typography className='flex flex-col items-center text-white sm:text-6xl text-2xl font-secondary font-medium'>
                  <span>REED</span>
                  <span>&</span>
                  <span>BOUGIES</span>
                </Typography>

                <Link to={'/candle-reed'}
                  className="bg-secondary flex p-3 px-5 justify-center items-center text-primary w-fit font-medium sm:text-base text-xs mt-5 hover:opacity-80"
                >
                  DECOUVRIR
                </Link>
              </div>
            </div>
          </div>
        </section>

        <ShowAdvertisement open={openShowAdvertisement} handleOpen={handleOpenShowAdvertisement} advertisement={advertisement} />
        <ShowCalendarVisitor open={openShowCalendar} handleOpen={handleOpenShowCalendar} calendar={calendar} />

      </div>
    </div>
  )
}

export default Shop