import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, CardBody, CardFooter, Chip, IconButton, Input, Option, Select, Tooltip, Typography } from '@material-tailwind/react'
import { PlusIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { BsPencil, BsTrash, BsEye, BsFileEarmarkArrowDown } from 'react-icons/bs'

import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

import { DatePicker } from 'antd'

import * as XLSX from 'xlsx';

import { AuthContext } from '../../contexts/AuthContext'
import { API_LINK } from '../../apiConfig'
import axios from 'axios'

import { AddCalendar } from '../../widgets/modals/calendars/AddCalendar'
import { ShowCalendar } from '../../widgets/modals/calendars/ShowCalendar'
import { DeleteCalendar } from '../../widgets/modals/calendars/DeleteCalendar'



const TABLE_HEAD = ["Date", "Description", ""];




const Calendars = () => {
    const { authTokens } = useContext(AuthContext);
    const [calendars, setCalendars] = useState([]);


    const [openAddCalendar, setOpenAddCalendar] = useState(false);
    const handleOpenAddCalendar = () => setOpenAddCalendar((cur) => !cur);

    const [openShowCalendar, setOpenShowCalendar] = useState(false);
    const handleOpenShowCalendar = () => setOpenShowCalendar((cur) => !cur);

    const [openDeleteCalendar, setOpenDeleteCalendar] = useState(false);
    const handleOpenDeleteCalendar = () => setOpenDeleteCalendar((cur) => !cur);

    const [selectedCalendar, setSelectedCalendar] = useState();

    const [date, setDate] = useState();

    useEffect(() => {
        axios.get(`${API_LINK}/calendars?date=${date}`, {
            headers: {
                Authorization: `Bearer ${authTokens?.accessToken}`
            }
        }).then(({ data }) => {
            setCalendars(data);
        }).catch((error) => {
            console.log(error);
        })
    }, [date])

    return (
        <div className="mt-12">


            <div className="flex justify-between flex-row items-center pb-4">
                <div></div>

                <div className='flex items-center'>
                    <Button size="sm" className="bg-[#E1B6A5] capitalize text-base font-normal text-white flex items-center shadow-none"
                        onClick={() => handleOpenAddCalendar()}
                    >
                        <PlusIcon className="w-4 h-4 mr-2" />
                        Ajouter un calendrier
                    </Button>
                </div>
            </div>

            <Card shadow={false} className="h-full w-full bg-secondary">
                <CardBody className={`px-0 xl:overflow-hidden`} /* overflow-scroll  */>

                    <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center p-4 text-primary">
                        <div>
                            <Typography variant="h4">
                                Calendriers récents
                            </Typography>
                            <Typography className="mt-1">
                                Ce sont des détails sur les calendriers
                            </Typography>
                        </div>
                        <div className="flex w-full shrink-0 gap-2 md:w-max">
                            <div className='sm:flex-1 sm:mr-6 sm:mb-0 mb-3'>
                                {/* <DatePicker date={date} setDate={setDate} /> */}
                                <DatePicker
                                    value={date}
                                    onChange={(date) => setDate(date)}
                                    placeholder='Sélectionner'
                                    allowClear
                                    className='py-2 px-8'
                                />
                            </div>
                        </div>
                    </div>

                    {calendars.length !== 0 ?
                        <div className={`${calendars.length !== 0 && 'overflow-x-scroll'}`}>
                            <table className="w-full min-w-max table-auto text-left">
                                <thead>
                                    <tr>
                                        {TABLE_HEAD.map((head) => (
                                            <th
                                                key={head}
                                                className="border-y border-primary bg-[#E1B6A5] p-4"
                                            >
                                                <Typography
                                                    variant="paragraph"
                                                    className=" leading-none"
                                                    color='white'
                                                >
                                                    {head}
                                                </Typography>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {calendars.map(
                                        (
                                            {
                                                id,
                                                description,
                                                date
                                            },
                                            index,
                                        ) => {
                                            const isLast = index === calendars.length - 1;
                                            const classes = isLast
                                                ? "p-4"
                                                : "p-4 border-b border-blue-gray-50";

                                            return (
                                                <tr key={id} className="text-primary">
                                                    <td className={classes}>
                                                        <div className="flex items-center gap-3">
                                                            <Typography
                                                                variant="paragraph"
                                                                color="blue-gray"
                                                                className="capitalize text-primary"
                                                            >
                                                                {format(new Date(date), 'dd MMMM yyyy', { locale: fr })}
                                                            </Typography>
                                                        </div>
                                                    </td>
                                                    <td className={classes}>
                                                        <Typography
                                                            variant="paragraph"
                                                            color="blue-gray"
                                                            className="capitalize text-primary"
                                                        >
                                                            {description}
                                                        </Typography>
                                                    </td>


                                                    <td className={classes}>
                                                        <Tooltip content="Détail">
                                                            <IconButton variant="text" onClick={() => { setSelectedCalendar({ id }); handleOpenShowCalendar() }}>
                                                                <BsEye className="h-4 w-4 text-primary" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip content="Supprimer">
                                                            <IconButton variant="text" onClick={() => { setSelectedCalendar({ id, date }); handleOpenDeleteCalendar() }}>
                                                                <BsTrash className="h-4 w-4 text-primary" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            );
                                        },
                                    )}
                                </tbody>
                            </table>
                        </div> :
                        (
                            <div className="w-full">
                                <div className="flex flex-col justify-center items-center bg-secondary text-primary p-6 rounded-none mx-6">
                                    <ExclamationTriangleIcon className='w-20 mb-4' />
                                    <Typography variant="lead" className="">
                                        {(date) ? "Vous n'avez pas un calendrier avec ce filtre" : "Vous n'avez encore pas de calendriers !"}
                                    </Typography>
                                </div>
                            </div>
                        )}
                </CardBody>
            </Card>

            <AddCalendar open={openAddCalendar} handleOpen={handleOpenAddCalendar} setCalendars={setCalendars} />
            <ShowCalendar open={openShowCalendar} handleOpen={handleOpenShowCalendar} selectedCalendar={selectedCalendar} setSelectedCalendar={setSelectedCalendar} />
            <DeleteCalendar open={openDeleteCalendar} handleOpen={handleOpenDeleteCalendar} selectedCalendar={selectedCalendar} setSelectedCalendar={setSelectedCalendar} setCalendars={setCalendars} />
            {/* <DeleteCode open={openDeleteCalendar} handleOpen={handleOpenDeleteCalendar} setCalendars={setCalendars} selectedCalendar={selectedCalendar} setSelectedCalendar={setSelectedCalendar} /> */}
        </div>
    )
}

export default Calendars