// import React, { useContext, useEffect, useState } from "react";
// import {
//     Button,
//     Dialog,
//     Card,
//     CardBody,
//     CardFooter,
//     Typography,
//     Spinner,
//     Input
// } from "@material-tailwind/react";
// import { PhotoIcon } from "@heroicons/react/24/outline";
// import { API_LINK } from "../../../apiConfig";
// import axios from "axios";
// import { AuthContext } from "../../../contexts/AuthContext";
// import { BsTrash, BsX } from "react-icons/bs";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";


// export const ShowCalendar = ({ open, handleOpen, selectedCalendar, setSelectedCalendar }) => {
//     const { authTokens } = useContext(AuthContext);
//     const [loading, setLoading] = useState(false);
//     const [calendar, setCalendar] = useState();


//     useEffect(() => {
//         if (open) {
//             const requestConfig = {
//                 headers: {
//                     Authorization: `Bearer ${authTokens?.accessToken}`,
//                     'Content-Type': 'multipart/form-data',
//                 }
//             };

//             axios.get(`${API_LINK}/calendars/${selectedCalendar?.id}`, requestConfig)
//                 .then(({ data }) => {
//                     setCalendar(data);
//                     console.log(data);

//                 })
//                 .catch((error) => {
//                     console.log(error);
//                 })
//                 .finally(() => {
//                     setLoading(false);
//                 });
//         }

//     }, [open, selectedCalendar])




//     const closeModalHandler = () => {
//         handleOpen();
//         setSelectedCalendar();
//     };


//     return (
//         <>
//             <Dialog
//                 size="xs"
//                 open={open}
//                 handler={closeModalHandler}
//                 className="bg-transparent shadow-none"
//             >
//                 {!loading ? (
//                     <Card className="mx-auto w-full bg-red-200">
//                         <CardBody className="flex flex-col gap-4 m-0 p-0">
//                             <div className="w-full">
//                                 <img
//                                     src={calendar ? `${API_LINK}/${calendar?.calendar}` : ''}
//                                     alt="calendar"
//                                     className="w-full"
//                                 />
//                             </div>

//                             {/* <div className="gap-4 w-full flex flex-row">
//                                 {!calendar ? (
//                                     <div className="flex items-center justify-center w-full">
//                                         <label className="flex rounded-lg border-4 border-dashed w-full p-10 group text-center">
//                                             <div className="h-full w-full text-center flex flex-col justify-center items-center">
//                                                 <PhotoIcon className="h-[120px] text-gray-200" />
//                                                 <span>Ajouter - Calendrier</span>
//                                             </div>
//                                             <input type="file" className="hidden" onChange={handleCalendarChange} />
//                                         </label>
//                                     </div>
//                                 ) : (
//                                     <div className="flex justify-center items-center relative w-full">
//                                         <div className="flex justify-center items-center relative w-full h-full">
//                                             <img src={calendar && calendar instanceof File ? URL.createObjectURL(calendar) : calendar} alt="calendar" className="rounded-none h-40" />
//                                         </div>


//                                     </div>
//                                 )}

//                                 {(!banner) ? (
//                                     <div className="flex items-center justify-center w-full">
//                                         <label className="flex rounded-lg border-4 border-dashed w-full p-10 group text-center">
//                                             <div className="h-full w-full text-center flex flex-col justify-center items-center">
//                                                 <PhotoIcon className="h-[120px] text-gray-200" />
//                                                 <span>Ajouter - Animation</span>
//                                             </div>
//                                             <input type="file" className="hidden" onChange={handleBannerChange} />
//                                         </label>
//                                     </div>
//                                 ) : (
//                                     <div className="flex justify-center items-center relative w-full">
//                                         <div className="flex justify-center items-center relative w-full h-full">
//                                             <img src={banner && banner instanceof File ? URL.createObjectURL(banner) : banner} alt="banner" className="rounded-none h-40" />
//                                         </div>

//                                     </div>
//                                 )}

//                             </div> */}

//                             {/* <div className="w-full grid grid-cols-1 gap-4">
//                                 <Input className='rounded-none bg-white' label="Description" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
//                                 <DatePicker
//                                     // value={date}
//                                     selected={date}
//                                     onChange={(date) => setDate(date)}
//                                     placeholder='Sélectionner une date'
//                                     allowClear
//                                     className='rounded-lg border-[1px] border-gray-500 py-[6px] px-8 w-full'
//                                 />
//                             </div> */}



//                         </CardBody>
//                         {/* <CardFooter className="pt-0">
//                             <Button variant="filled" className="bg-primary rounded-none shadow-none" onClick={addCalendarHandler} fullWidth>
//                                 Ajouter
//                             </Button>
//                         </CardFooter> */}
//                     </Card>
//                 ) : (
//                     <div className="flex justify-center items-center p-5">
//                         <Spinner />
//                     </div>
//                 )}
//             </Dialog>
//         </>
//     );
// };


// import React, { useContext, useEffect, useState } from "react";
// import {
//     Button,
//     Dialog,
//     Card,
//     CardBody,
//     CardFooter,
//     Typography,
//     Spinner,
//     Input
// } from "@material-tailwind/react";
// import { PhotoIcon } from "@heroicons/react/24/outline";
// import { API_LINK } from "../../../apiConfig";
// import axios from "axios";
// import { AuthContext } from "../../../contexts/AuthContext";
// import { BsTrash, BsX } from "react-icons/bs";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";


// export const ShowCalendar = ({ open, handleOpen, selectedCalendar, setSelectedCalendar }) => {
//     const { authTokens } = useContext(AuthContext);
//     const [loading, setLoading] = useState(false);
//     const [calendar, setCalendar] = useState();
//     const [isCalendarClicked, setIsCalendarClicked] = useState(false);  // État pour savoir si l'image du calendrier a été cliquée


//     useEffect(() => {
//         if (open) {
//             const requestConfig = {
//                 headers: {
//                     Authorization: `Bearer ${authTokens?.accessToken}`,
//                     'Content-Type': 'multipart/form-data',
//                 }
//             };

//             axios.get(`${API_LINK}/calendars/${selectedCalendar?.id}`, requestConfig)
//                 .then(({ data }) => {
//                     setCalendar(data);
//                     console.log(data);
//                 })
//                 .catch((error) => {
//                     console.log(error);
//                 })
//                 .finally(() => {
//                     setLoading(false);
//                 });
//         }
//     }, [open, selectedCalendar]);

//     const closeModalHandler = () => {
//         handleOpen();
//         setSelectedCalendar();
//         setIsCalendarClicked(false);  // Réinitialiser l'état lorsqu'on ferme la modal
//     };

//     const handleCalendarClick = () => {
//         setIsCalendarClicked(true);  // Mettre l'état à true lorsque l'on clique sur l'image du calendrier
//     };

//     return (
//         <>
//             <Dialog
//                 size="xs"
//                 open={open}
//                 handler={closeModalHandler}
//                 className="bg-transparent shadow-none rounded-none"
//             >
//                 {!loading ? (
//                     <Card className="mx-auto w-full rounded-none">
//                         <CardBody className="flex flex-col gap-4 m-0 p-0 rounded-none">
//                             <div className="w-full">
//                                 <div className="relative w-full">
//                                     {/* Affichage du calendrier avec flou lorsqu'il est cliqué */}
//                                     <img
//                                         src={calendar ? `${API_LINK}/${calendar?.calendar}` : ''}
//                                         alt="calendar"
//                                         className={`w-full ${isCalendarClicked ? 'filter blur-sm' : ''}`}  // Ajouter un flou si cliqué
//                                         onClick={handleCalendarClick}  // Gérer le clic
//                                     />
                                    
//                                     {/* Affichage de l'image de la bannière par-dessus le calendrier */}
//                                     {isCalendarClicked && calendar?.banner && (
//                                         <img
//                                             src={calendar ? `${API_LINK}/${calendar?.banner}` : ''}
//                                             alt="banner"
//                                             className="absolute top-0 left-0 w-full h-full object-cover opacity-80"  // Mettre l'image de la bannière par-dessus
//                                         />
//                                     )}
//                                 </div>
//                             </div>
//                         </CardBody>
//                     </Card>
//                 ) : (
//                     <div className="flex justify-center items-center p-5">
//                         <Spinner />
//                     </div>
//                 )}
//             </Dialog>
//         </>
//     );
// };


import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Spinner,
    Input
} from "@material-tailwind/react";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { API_LINK } from "../../../apiConfig";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { BsTrash, BsX } from "react-icons/bs";


export const ShowCalendar = ({ open, handleOpen, selectedCalendar, setSelectedCalendar }) => {
    const { authTokens } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [calendar, setCalendar] = useState();
    const [isCalendarClicked, setIsCalendarClicked] = useState(false);  // État pour savoir si l'image du calendrier a été cliquée


    useEffect(() => {
        if (open) {
            const requestConfig = {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`,
                    'Content-Type': 'multipart/form-data',
                }
            };

            axios.get(`${API_LINK}/calendars/${selectedCalendar?.id}`, requestConfig)
                .then(({ data }) => {
                    setCalendar(data);
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [open, selectedCalendar]);

    const closeModalHandler = () => {
        handleOpen();
        setSelectedCalendar();
        setIsCalendarClicked(false);  // Réinitialiser l'état lorsqu'on ferme la modal
    };

    const handleCalendarClick = () => {
        setIsCalendarClicked(true);  // Mettre l'état à true lorsque l'on clique sur l'image du calendrier
    };

    return (
        <>
            <Dialog
                size="xs"
                open={open}
                handler={closeModalHandler}
                className="bg-transparent shadow-none rounded-none"
            >
                {!loading ? (
                    <Card className="mx-auto w-full rounded-none">
                        <CardBody className="flex flex-col gap-4 m-0 p-0 rounded-none">
                            <div className="w-full">
                                <div className="relative w-full">
                                    {/* Affichage du calendrier avec transition pour flou et disparition progressive */}
                                    <img
                                        src={calendar ? `${API_LINK}/${calendar?.calendar}` : ''}
                                        alt="calendar"
                                        className={`w-full transition-all h-full duration-500 ${isCalendarClicked ? 'filter blur-sm opacity-100' : ''}`}  // Transition du flou et de l'opacité
                                        onClick={handleCalendarClick}  // Gérer le clic
                                    />
                                    
                                    {/* Affichage de l'image de la bannière par-dessus avec animation */}
                                    {isCalendarClicked && calendar?.banner && (
                                        <img
                                            src={calendar ? `${API_LINK}/${calendar?.banner}` : ''}
                                            alt="banner"
                                            className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ease-in-out ${!isCalendarClicked ? 'opacity-0' : 'opacity-100' } `}
                                        />
                                    )}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                ) : (
                    <div className="flex justify-center items-center p-5">
                        <Spinner />
                    </div>
                )}
            </Dialog>
        </>
    );
};
