import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Spinner,
    Input
} from "@material-tailwind/react";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { API_LINK } from "../../../apiConfig";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { BsTrash, BsX } from "react-icons/bs";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';



export const AddCalendar = ({ open, handleOpen, setCalendars }) => {
    const { authTokens } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [advertisement, setAdvertisement] = useState();

    const [date, setDate] = useState();
    const [description, setDescription] = useState();
    const [calendar, setCalendar] = useState(null); // Will hold the calendar image or file
    const [banner, setBanner] = useState(null); // Will hold the banner image or file




    const addCalendarHandler = () => {
        setLoading(true);
        if (date && description && calendar && banner) {
            const formData = new FormData();
            formData.append('date', date);
            formData.append('description', description);
            formData.append('calendar', calendar);
            formData.append('banner', banner);


            const requestConfig = {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`,
                    'Content-Type': 'multipart/form-data',
                }
            };

            // Add new calendar (POST request)
            axios.post(`${API_LINK}/calendars`, formData, requestConfig)
                .then(({ data }) => {
                    // Add the new calendar to the local state
                    setCalendars(calendars => [data, ...calendars]);
                    closeModalHandler();
                    alert('Votre calendrier a bien été ajoutée');
                })
                .catch((error) => {
                    console.log(error);
                    alert('Erreur lors de l\'ajout du calendrier.');
                })
                .finally(() => {
                    setLoading(false);
                });

        }
    };

    const deleteAdvertisementHandler = () => {
        // setLoading(true);
        // if (advertisement) {
        //     const requestConfig = {
        //         headers: {
        //             Authorization: `Bearer ${authTokens?.accessToken}`
        //         }
        //     };

        //     axios.delete(`${API_LINK}/advertisements/${advertisement?.id}`, requestConfig).then(({ data }) => {
        //         closeModalHandler();
        //     }).catch((error) => {
        //         console.log(error);
        //     }).finally(() => {
        //         setLoading(false);
        //     });
        // }
    };

    const closeModalHandler = () => {
        handleOpen();
        setCalendar();
        setBanner();
        setDescription('');
        setDate();
    };

    // Handle file input changes for calendar and banner
    const handleCalendarChange = (event) => {
        setCalendar(event.target.files[0]); // Set the file for calendar
    };

    const handleBannerChange = (event) => {
        setBanner(event.target.files[0]); // Set the file for banner
    };


    const handleRemoveImage = () => {
        setCalendar(null);
        setBanner(null);
    };


    return (
        <>
            <Dialog
                size="sm"
                open={open}
                handler={closeModalHandler}
                className="bg-transparent shadow-none"
            >
                {!loading ? (
                    <Card className="mx-auto w-full">
                        <CardBody className="flex flex-col gap-4">
                            <Typography variant="h4" color="blue-gray">
                                Ajouter un calendrier
                            </Typography>

                            <div className="gap-4 w-full flex flex-row">
                                {/* Image pour le web */}
                                {!calendar ? (
                                    <div className="flex items-center justify-center w-full">
                                        <label className="flex rounded-lg border-4 border-dashed w-full p-10 group text-center">
                                            <div className="h-full w-full text-center flex flex-col justify-center items-center">
                                                <PhotoIcon className="h-[120px] text-gray-200" />
                                                <span>Ajouter - Calendrier</span>
                                            </div>
                                            <input type="file" className="hidden" onChange={handleCalendarChange} />
                                        </label>
                                    </div>
                                ) : (
                                    <div className="flex justify-center items-center relative w-full">
                                        <div className="flex justify-center items-center relative w-full h-full">
                                            <img src={calendar && calendar instanceof File ? URL.createObjectURL(calendar) : calendar} alt="calendar" className="rounded-none h-40" />
                                        </div>


                                    </div>
                                )}

                                {/* Image pour le mobile */}
                                {(!banner) ? (
                                    <div className="flex items-center justify-center w-full">
                                        <label className="flex rounded-lg border-4 border-dashed w-full p-10 group text-center">
                                            <div className="h-full w-full text-center flex flex-col justify-center items-center">
                                                <PhotoIcon className="h-[120px] text-gray-200" />
                                                <span>Ajouter - Animation</span>
                                            </div>
                                            <input type="file" className="hidden" onChange={handleBannerChange} />
                                        </label>
                                    </div>
                                ) : (
                                    <div className="flex justify-center items-center relative w-full">
                                        <div className="flex justify-center items-center relative w-full h-full">
                                            <img src={banner && banner instanceof File ? URL.createObjectURL(banner) : banner} alt="banner" className="rounded-none h-40" />
                                        </div>

                                    </div>
                                )}

                            </div>

                            <div className="w-full grid grid-cols-1 gap-4">
                                <Input className='rounded-none bg-white' label="Description" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                                <DatePicker
                                    // value={date}
                                    selected={date}
                                    onChange={(date) => setDate(date)}
                                    placeholder='Sélectionner une date'
                                    allowClear
                                    className='rounded-lg border-[1px] border-gray-500 py-[6px] px-8 w-full'
                                />
                            </div>

                            {/* {((image || advertisement?.imageUrlWeb || imageData) || (mobileImage || advertisement?.imageUrlMobile || mobileImageData)) &&
                                <div className="flex flex-row items-center gap-4">
                                    <button className="flex justify-center items-center text-white w-full h-10 bg-primary rounded-none"
                                        onClick={() => handleRemoveImage()}
                                    >
                                        <BsX className="text-3xl" />
                                    </button>
                                    {advertisement && <button
                                        className="w-full h-10 bg-red-400 flex justify-center items-center text-white rounded-none"
                                        onClick={() => deleteAdvertisementHandler()}
                                    >
                                        <BsTrash className="text-xl" />
                                    </button>}
                                </div>
                            } */}


                        </CardBody>
                        <CardFooter className="pt-0">
                            <Button variant="filled" className="bg-[#E1B6A5] shadow-none" onClick={addCalendarHandler} fullWidth>
                                Ajouter
                            </Button>
                        </CardFooter>
                    </Card>
                ) : (
                    <div className="flex justify-center items-center p-5">
                        <Spinner />
                    </div>
                )}
            </Dialog>
        </>
    );
};
