import React, { useContext, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Spinner,
} from "@material-tailwind/react";

import { format } from 'date-fns'
import { fr } from 'date-fns/locale'

import { API_LINK } from "../../../apiConfig";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";

export const DeleteCalendar = ({ open, handleOpen, selectedCalendar, setSelectedCalendar, setCalendars }) => {
    const { authTokens } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const deleteCalendarHandler = () => {
        setLoading(true);
        if (selectedCalendar) {
            axios.delete(`${API_LINK}/calendars/${selectedCalendar?.id}`, {
                headers: {
                    Authorization: `Bearer ${authTokens?.accessToken}`
                }
            }).then(({ data }) => {
                setCalendars((calendars) => calendars.filter((calendar) => calendar.id !== selectedCalendar.id));
                closeModalHandler()
            }).catch((error) => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    }


    const closeModalHandler = () => {
        handleOpen();
        setSelectedCalendar(null);
    }

    return (
        <>
            <Dialog
                size="sm"
                open={open}
                handler={closeModalHandler}
                className="bg-transparent shadow-none"
            >
                {selectedCalendar && selectedCalendar?.date ? (<Card className="mx-auto w-full">
                    <CardBody className="flex flex-col gap-4">
                        <Typography variant="h4" color="blue-gray">
                            Supprimer un calendrier
                        </Typography>
                        <div className="w-full flex justify-center">
                            <Typography variant="h6" color="blue-gray" className="w-2/3 text-center">
                                Voulez-vous vraiment supprimer le calendrier de la date <span className="font-bold capitalize">« {format(new Date(selectedCalendar?.date), 'dd MMMM yyyy', { locale: fr })} »</span> de votre liste de calendriers
                            </Typography>
                        </div>
                    </CardBody>
                    <CardFooter className="pt-0">
                        <Button variant="filled" className="bg-red-400 shadow-none" onClick={() => deleteCalendarHandler()} fullWidth>
                            Supprimer
                        </Button>
                    </CardFooter>
                </Card>
                ) : (
                    <div className="flex justify-center items-center p-5">
                        <Spinner />
                    </div>
                )}
            </Dialog>
        </>
    );
}