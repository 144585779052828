import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    Card,
    CardBody,
    CardFooter,
    Typography,
    Spinner,
    Input
} from "@material-tailwind/react";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { API_LINK } from "../../../apiConfig";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { BsTrash, BsX } from "react-icons/bs";
import { Link } from "react-router-dom";


export const ShowCalendarVisitor = ({ handleOpen, open, calendar }) => {
    const [isCalendarClicked, setIsCalendarClicked] = useState(false);

    const handleCalendarClick = () => {
        setIsCalendarClicked(true);  // Mettre l'état à true lorsque l'on clique sur l'image du calendrier
    };

    return (
        <>
            <Dialog
                size="xs"
                open={open}
                // handler={handleOpen}
                className="bg-transparent shadow-none rounded-none"
            >
                <Card className="mx-auto w-full rounded-none">
                    <CardBody className="flex flex-col gap-4 m-0 p-0 rounded-none">
                        <div className="w-full">
                            <div className="relative w-full">
                                {/* Affichage du calendrier avec transition pour flou et disparition progressive */}
                                <img
                                    src={`${API_LINK}/${calendar?.calendar}`}
                                    alt="calendar"
                                    className={`w-full transition-all h-full duration-500 ${isCalendarClicked ? 'filter blur-sm opacity-100' : ''}`}
                                    onClick={handleCalendarClick}  // Gérer le clic
                                />

                                {/* Affichage de l'image de la bannière par-dessus avec animation */}
                                {isCalendarClicked && calendar?.banner && (
                                    <div className="absolute top-0 left-0 w-full h-full">
                                        <img
                                            src={`${API_LINK}/${calendar?.banner}`}
                                            alt="banner"
                                            className={`w-full h-full object-cover transition-opacity duration-1000 ease-in-out ${!isCalendarClicked ? 'opacity-0' : 'opacity-100'} `}
                                        />
                                        <div
                                            className="absolute bottom-10 inset-x-0 flex justify-center"
                                        >
                                            <Button className={`rounded-none transition-opacity duration-1000 ease-in-out ${!isCalendarClicked ? 'opacity-0' : 'opacity-100'} `}>
                                                <Link to='/diffuser'>
                                                {/* <Link to='/product/79'> */}
                                                    Profiter de l'offre
                                                </Link>
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </CardBody>
                </Card>

            </Dialog>
        </>
    );
};
